import { useCookieConfiguration, useCookiePermissionSelection } from '@kaliber/cookie-consent'
import { useClientConfig } from '/machinery/ClientConfig'
import { pushToDataLayer } from '/machinery/tracking/pushToDataLayer'
import { cookieConfigurationEvent } from '/machinery/tracking/cookieConfiguration'
import { useReportError } from '/machinery/ReportError'
import { routeMap } from '/routeMap'
import { ButtonPrimary } from '/features/buildingBlocks/Button'

import styles from './CookiePermissions.css'

const translations = {
  statistics: {
    title: 'Statistieken'
  },
  necessary: {
    title: 'Noodzakelijk'
  },
  marketing: {
    title: 'Marketing'
  },
  preferences: {
    title: 'Voorkeuren'
  },
}

export function CookiePermissions({ initialConfiguration, layoutClassName = undefined }) {
  const clientConfig = useClientConfig()
  const availablePermissions = clientConfig.cookieConfiguration.availablePermissions
  const reportError = useReportError()
  const classifications = useClassifications({ availablePermissions: clientConfig.cookieConfiguration.availablePermissions })

  const {
    storeSelection,
  } = useCookieConfiguration({
    availablePermissions,
    cookieEndpoint: routeMap.api.v1.cookieConfiguration(),
    initialConfiguration,
    onChange: pushCookieConsent,
    onError: reportError
  })

  const {
    selection,
    grantPermission,
    denyPermission,
  } = useCookiePermissionSelection({
    initialConfiguration
  })

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <div className={styles.cookiePermissionsWrapper}>
        {classifications.map(({ name, classification, required }) => (
          <CookiePermission
            id={classification}
            key={classification}
            title={translations[classification].title}
            onToggleChange={({ value }) => handlePermissionChange({ classification, value })}
            active={selection[name] === 'granted'}
            disabled={required}
            layoutClassName={styles.cookiePermissionLayout}
          />
        ))}
      </div>

      <div className={styles.buttonWrapper}>
        <ButtonPrimary onClick={handleStoreSelection}  dataX='click-to-save-selection'>
          Wijzigingen opslaan
        </ButtonPrimary>
      </div>
    </div>
  )

  function handleStoreSelection() {
    storeSelection({ permissions: selection })
  }

  function handlePermissionChange({ classification, value }) {
    availablePermissions
      .filter(x => x.classification === classification)
      .forEach(x => handleValueChange({ id: x.name, value }))
  }

  function handleValueChange({ id, value }) {
    value === true
      ? grantPermission(id)
      : denyPermission(id)
  }
}

function CookiePermission({
  id,
  title,
  active,
  onToggleChange,
  disabled = undefined,
  layoutClassName = undefined
}) {
  return (
    <div className={cx(styles.componentCookiePermission, disabled && styles.isDisabled, layoutClassName)}>
      <h4 className={cx(styles.heading, disabled && styles.isDisabled)}>{title}</h4>
      <button
        type="button"
        onClick={handleClick}
        className={cx(
          styles.toggleButton,
          active && styles.isActive,
          disabled && styles.isDisabled,
        )}
        {... { id, disabled }}
      >
        <span className={cx(styles.option, active && styles.isActive)}>Aan</span>
        <span className={cx(styles.option, !active && styles.isActive)}>Uit</span>
        <span className={cx( styles.slider, disabled && styles.isDisabled, active && styles.isActive)} />
      </button>
    </div>
  )


  function handleClick() {
    onToggleChange({ id, value: !active })
  }
}

function pushCookieConsent(configuration) {
  pushToDataLayer(cookieConfigurationEvent(configuration))
}

function useClassifications({ availablePermissions }) {
  const [classifications, setClassifications] = React.useState([])

  React.useEffect(() => {
    const uniqueClassifications = Array
      .from(new Set(availablePermissions.map(x => x.classification)))
      .map(classification => availablePermissions.find(x => x.classification === classification))
    setClassifications(uniqueClassifications)
  }, [availablePermissions])

  return Object.values(classifications)
}
