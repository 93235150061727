import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { CookiePermissions } from './CookiePermissions'

const client = new QueryClient()

export default function CookiePermissionsUniversal({ initialConfiguration, layoutClassName = undefined }) {
  return (
    <QueryClientProvider {...{ client }}>
      <CookiePermissions {...{ initialConfiguration, layoutClassName }} />
    </QueryClientProvider>
  )
}
